<template>
  <div class="field">
    <div class="control">
      <button
        v-if="currentUser"
        :class="buttonClass"
        :disabled="submitting || isAdmin"
        @click="showConfirmationModal"
      >
        Delete Account
      </button>

      <BaseModal
        v-if="showModal"
        title="Delete Account?"
        @close="cancel"
      >
        <template v-slot>
          <p class="block">
            Hi, {{ currentUser.name }}, are you sure you want to delete your account? <em>This cannot be undone.</em>
          </p>
          <p
            class="block"
            v-if="currentUser.balance > 0"
          >
            You currently have a balance of <strong>{{ USD(currentUser.balance).format() }}</strong>, which would be lost if you delete your account now. <em>Please wait until <strong>after</strong> your next payout to delete your account so that you get paid your full earnings.</em>
          </p>
          <p
            v-if="adminOfOrganizations.length > 0"
          >
            You are an admin of the following business(es): <strong>{{ adminOfOrganizations.join(', ') }}</strong>. If you are the only admin, any active deals will be deactivated and you will lose any unused credits. <em>Please contact us <strong>before</strong> deleting your account so we can refund your unused credits.</em>
          </p>
        </template>
        <template v-slot:footer>
          <button
            class="button"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            class="button is-danger"
            @click="showFinalModal"
          >
            DELETE
          </button>
        </template>
      </BaseModal>

      <BaseModal
        v-if="showModal2"
        title="Are You Sure?"
        @close="cancel"
      >
        <template v-slot>
          <p class="block">If you delete your account now, you will lose any account balance and/or credits. <strong>This cannot be undone.</strong></p>
          <p class="block"><em>If you are absolutely sure, type "DELETE" below:</em></p>
          <p class="block">
            <input
              class="input is-danger"
              type="text"
              v-model="confirmText"
            />
          </p>
        </template>
        <template v-slot:footer>
          <button
            class="button"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            :class="buttonClass"
            :disabled="confirmText != 'DELETE'"
            @click="deleteAccount"
          >
            PERMANENTLY DELETE
          </button>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors/index.js";
import BaseModal from "@/components/modal/BaseModal.vue";

export default {
  components: {
    BaseModal
  },
  data() {
    return {
      submitting: false,
      showModal: false,
      showModal2: false,
      confirmText: ""
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    adminOfOrganizations() {
      return this.currentUser.organization_access.filter(o => o.is_admin || o.is_owner).map(o => o.name)
    },
    buttonClass() {
      return this.submitting ? "button is-danger is-loading" : "button is-danger"
    }
  },
  methods: {
    showConfirmationModal() {
      this.showModal = true
    },
    showFinalModal() {
      this.showModal2 = true
    },
    cancel() {
      this.showModal = false
      this.showModal2 = false
      this.$emit('cancel')
    },
    go(path) {
      this.$router.push(path)
    },
    deleteAccount() {
      if (this.confirmText !== "DELETE") {
        this.cancel()
        return
      }

      this.submitting = true

      const params = {
        slug: this.currentUser.slug
      }
      this.$store.dispatch("deleteCurrentUser", params)
        .then(() => {
          this.go("/logout?r=deletion")
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error
          }
        })
        .finally(() => {
          this.submitting = false
          this.showModal = false
          this.showModal2 = false
        })
    }
  }
}
</script>
