<template>
  <SettingsPage>
    <Notification
      v-if="error"
      type="danger"
    >
      {{ error }}
    </Notification>

    <form @submit.prevent="onSubmit">
      <BaseInput
        label="Name" 
        type="text"
        v-model.trim="$v.user.name.$model"
        :validation="$v.user.name"
        @input="detectChange()"
      />
      <BaseInput
        label="Email" 
        type="email"
        v-model.trim="$v.user.email.$model"
        :validation="$v.user.email"
        @input="detectChange()"
      />
      <div class="field">
        <label class="label">Default search radius</label>
        <div class="control">
          <div class="select">
            <select
              v-model="user.notification_radius"
              @change="detectChange()"
            >
              <option 
                v-for="opt in notification_radius_options"
                :key="opt.value"
                :value="opt.value"
              >
                {{ opt.label || 'No label' }}  
              </option>
            </select>
          </div>
        </div>
      </div>
      <BaseSubmit
        label="Save"
        :disabled="$v.$invalid || noChanges"
        :submitting="submitting"
        :clearShow="true"
      />
      <DeleteAccountButton />
    </form>
  </SettingsPage>
</template>

<script>
import {AuthError, NetworkError} from "@/errors/index.js";
import { email, required } from 'vuelidate/lib/validators';
import SettingsPage from "@/components/SettingsPage.vue";
import Notification from "@/components/Notification.vue";
import BaseInput from "@/components/form/BaseInput.vue";
import BaseSubmit from "@/components/form/BaseSubmit.vue";
import DeleteAccountButton from "@/components/DeleteAccountButton.vue";

export default {
  components: {
    SettingsPage,
    Notification,
    BaseInput,
    BaseSubmit,
    DeleteAccountButton
  },
  data() {
    return {
      loading: false,
      error: null,
      noChanges: true,
      submitting: false,
      user:  {
        name: '',
        email: '',
        notification_radius: 100
      },
      notification_radius_options: [
        {
          label: "1 mile",
          value: 1
        },
        {
          label: "5 miles",
          value: 5
        },
        {
          label: "10 miles",
          value: 10
        },
        {
          label: "25 miles",
          value: 25
        },
        {
          label: "50 miles",
          value: 50
        },
        {
          label: "100 miles",
          value: 100
        }
      ]
    }
  },
  validations: {
     user:  {
      name: { required },
      email: { required, email }
    }
  },
  mounted() {
    this.user = this.$store.getters.currentUser;
  },
  methods: {
    detectChange() {
      this.noChanges = false
    },
    reset() {
      this.noChanges = true
      this.$router.go()
    },
    onSubmit() {
      this.submitting = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let params = {
        slug: this.user.slug,
        user: {
          name: this.user.name,
          email: this.user.email,
          notification_radius: this.user.notification_radius
        }
      }
      this.$store.dispatch("updateCurrentUser", params)
        .then(() => {
          this.noChanges = true
          this.submitting = false;
        })
        .catch(error => {
          if (error instanceof AuthError || error instanceof NetworkError) {
            throw error
          } else {
            console.log(error)
            this.error = error
          }
        });
    },
  }
}
</script>
